
























































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomTextTagEnum,
  AtomLoading,
  AtomIcon,
} from "@/v2/new-design-system";
import { t } from "@/i18n";
import { InboxConversationList } from "../conversation-list";
import { InboxEmptyInbox } from "../empty-inbox";
import { IInboxConversation } from "../domain/conversation";
import { useSocket } from "../socket";
import { wsConversationAdapter, wsIncomingMessageAdapter } from "../socket/sub";
import { IInboxIncomingMessage } from "../domain/message";
import { IInboxSetup } from "../domain/setup";
import { sortDescBy } from "@/v2/util";
import { IInboxRouteEnum } from "../inbox-route.enum";
import { useRouter } from "@/router";
import { fetchInboxSetup } from "@/v2/repo/inbox/fetch-inbox-setup";
import { VTooltip } from "v-tooltip";

const css = bemBuilder("inbox-main");

export default defineComponent({
  name: "InboxMain",
  directives: {
    tooltip: VTooltip,
  },
  components: {
    InboxConversationList,
    InboxEmptyInbox,
    AtomText,
    AtomLoading,
    AtomIcon,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const isInitialLoading = ref(true);
    const isLoadingMoreConversations = ref(false);
    const conversations = ref<IInboxConversation[]>([]);
    const isLoadingSetup = ref(true);
    const businessSetup = ref<IInboxSetup>();

    const { emitEvent, onEvent, offEvent } = useSocket();

    function handleConversations(data: Record<string, any>[]) {
      const test = data.map((c) => wsConversationAdapter(c));

      test.forEach((c) =>
        conversations.value.push({
          id: c.id,
          businessId: c.businessId,
          contact: c.contact,
          channel: c.channel,
          hasUnreadMessages: c.hasUnreadMessages,
          createdAt: c.createdAt,
          lastMessageAt: c.lastMessageAt,
          lastMessageReceivedAt: c.lastMessageReceivedAt,
        })
      );

      isInitialLoading.value = false;

      if (
        conversations.value.length <=
        conversationIndexLastRequest.value - conversationPerAdiciontalPage.value
      ) {
        hasAllConversationsLoaded.value = true;
      }

      conversationIndexLastRequest.value =
        conversationIndexLastRequest.value +
        conversationPerAdiciontalPage.value;

      isLoadingMoreConversations.value = false;
    }
    onEvent("CONVERSATIONS_LOADED", handleConversations);

    function removeConversation(id: string): void {
      conversations.value = conversations.value.filter((c) => c.id !== id);
    }

    function handleMessageReceived(data: Record<string, any>) {
      const message: IInboxIncomingMessage = wsIncomingMessageAdapter(data);

      const conversationId = message.conversation.id;
      removeConversation(conversationId);

      conversations.value.unshift(message.conversation);

      if (
        message.conversation.id === props.id &&
        message.conversation.hasUnreadMessages
      ) {
        markConversationAsRead(props.id);
      }
    }
    onEvent("MESSAGE_RECEIVED", handleMessageReceived);

    function markConversationAsRead(id: string): void {
      emitEvent("MARK_CONVERSATION_AS_READ", {
        conversationId: id,
      });

      const conversation = conversations.value.find((c) => c.id === id);

      if (!conversation) return;

      removeConversation(conversation.id);
      conversation.hasUnreadMessages = false;

      conversations.value = sortDescBy(
        [...conversations.value, ...[conversation]],
        "lastMessageAt"
      );
    }

    const conversationsWrapperElement = ref() as Ref<HTMLDivElement>;
    const conversationsListElement = ref() as Ref<HTMLDivElement>;
    async function handleInfinityScroll() {
      if (
        conversationsWrapperElement.value.scrollTop >=
        conversationsListElement.value.scrollHeight -
          conversationsWrapperElement.value.offsetHeight -
          200
      ) {
        requestConversations();
      }
    }

    watch(
      () => props.id,
      (newVal) => {
        const selectedConversation = conversations.value.find(
          (c) => c.id === newVal
        );

        if (selectedConversation?.hasUnreadMessages) {
          markConversationAsRead(selectedConversation.id);
        }
      }
    );

    const conversationPerAdiciontalPage = ref(80);
    const conversationIndexLastRequest = ref(0);
    const hasAllConversationsLoaded = ref(false);
    function requestConversations() {
      if (hasAllConversationsLoaded.value) {
        conversationsWrapperElement.value.removeEventListener(
          "scroll",
          handleInfinityScroll
        );
        return;
      }

      if (isLoadingMoreConversations.value) return;
      isLoadingMoreConversations.value = true;

      emitEvent("LOAD_CONVERSATIONS_BY_RANGE", {
        businessId: props.businessId,
        rangeStart: conversationIndexLastRequest.value,
        rangeEnd:
          conversationIndexLastRequest.value +
          conversationPerAdiciontalPage.value,
      });
    }

    requestConversations();

    onBeforeMount(async () => {
      const router = useRouter();
      const { isWhatsAppEnabled, isInstagramEnabled } = await fetchInboxSetup(
        props.businessId
      );

      businessSetup.value = { isWhatsAppEnabled, isInstagramEnabled };

      if (
        !businessSetup.value?.isWhatsAppEnabled &&
        !businessSetup.value?.isInstagramEnabled
      ) {
        router.replace({
          name: IInboxRouteEnum.SETTINGS,
          params: {
            businessId: props.businessId,
          },
        });
      }

      isLoadingSetup.value = false;
    });

    onMounted(() => {
      conversationsWrapperElement.value.addEventListener(
        "scroll",
        handleInfinityScroll
      );
    });

    onBeforeUnmount(() => {
      offEvent("CONVERSATIONS_LOADED", handleConversations);
      offEvent("MESSAGE_RECEIVED", handleMessageReceived);
      conversationsWrapperElement.value.removeEventListener(
        "scroll",
        handleInfinityScroll
      );
    });

    return {
      css,
      t,
      conversations,
      isInitialLoading,
      isLoadingSetup,
      conversationsWrapperElement,
      conversationsListElement,
      businessSetup,
      isLoadingMoreConversations,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomTextTagEnum,
      IInboxRouteEnum,
    };
  },
});
