






























































/* global FB fb */
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
} from "@vue/composition-api";
import {
  AtomText,
  AtomTextColorEnum,
  AtomTextTagEnum,
  AtomTextTypeEnum,
  OrgHeaderInfo,
  AtomButton,
  AtomButtonSizeEnum,
  AtomLoading,
  AtomAttentionBadge,
  AtomAttentionBadgeTypeEnum,
  AtomCard,
  MolGuideLinkArticleEnum,
  MolIframeModal,
  MolSocialMediaLogo,
  MolSocialMediaLogoTypeEnum,
} from "@/v2/new-design-system";
import { t } from "@/i18n";
import { fetchInboxSetup } from "@/v2/repo/inbox/fetch-inbox-setup";
import { useHelpers } from "@/v2/composable/use-helpers";
import { InboxFeaturesDisplay } from "../features-display";
import { InboxWhatsAppSignup } from "../whatsapp-signup";
import { IInboxChannel } from "../domain/channel";
import { inboxTrack } from "../track";
import { EventEnum } from "@/v2/enum";
import { watchFlag, unWatchFlag } from "@/v2/core/feature-flag";
import { useFacebookGraphApi } from "@/v2/composable/use-facebook-graph-api";

const css = bemBuilder("inbox-settings");

export default defineComponent({
  name: "InboxSettings",
  components: {
    InboxFeaturesDisplay,
    OrgHeaderInfo,
    AtomLoading,
    AtomCard,
    AtomText,
    AtomButton,
    MolIframeModal,
    AtomAttentionBadge,
    MolSocialMediaLogo,
    InboxWhatsAppSignup,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isSetupLoaded = ref<boolean>(false);
    const isWhatsAppEnabled = ref<boolean>(false);
    const business = useHelpers().getCurrentBusiness(props.businessId);
    const tenant = useHelpers().getTenant();
    const isConnectWhatsAppModalVisible = ref(false);

    const { startFacebook } = useFacebookGraphApi();

    const vm = getCurrentInstance();

    function onConnectWhatsApp() {
      isConnectWhatsAppModalVisible.value = true;
      inboxTrack({
        // @ts-ignore
        analytics: vm?.proxy.$analytics,
        businessId: props.businessId,
        eventName: EventEnum.INBOX_STARTED_WHATSAPP_CONNECTION,
      });
    }

    function onWhatsAppConnected() {
      inboxTrack({
        // @ts-ignore
        analytics: vm?.proxy.$analytics,
        businessId: props.businessId,
        eventName: EventEnum.INBOX_CONNECTED_WHATSAPP,
      });
    }

    async function loadSetup() {
      const inboxSetup = await fetchInboxSetup(props.businessId);
      isWhatsAppEnabled.value = inboxSetup.isWhatsAppEnabled;
      isSetupLoaded.value = true;
    }

    const showEmbeddedSignup = ref(false);

    const updateFlagHandler = (newValue: boolean) => {
      showEmbeddedSignup.value = newValue;
    };

    watchFlag("whatsapp-embedded-signup", updateFlagHandler);

    onBeforeUnmount(() => {
      unWatchFlag("whatsapp-embedded-signup", updateFlagHandler);
    });

    onBeforeMount(() => {
      loadSetup();
    });

    onMounted(() => {
      startFacebook();
    });

    return {
      css,
      business,
      tenant,
      isWhatsAppEnabled,
      isSetupLoaded,
      showEmbeddedSignup,
      t,
      AtomTextColorEnum,
      AtomTextTagEnum,
      AtomTextTypeEnum,
      AtomButtonSizeEnum,
      AtomAttentionBadgeTypeEnum,
      MolSocialMediaLogoTypeEnum,
      MolGuideLinkArticleEnum,
      IInboxChannel,
      isConnectWhatsAppModalVisible,
      onConnectWhatsApp,
      onWhatsAppConnected,
    };
  },
});
